import { Box, Heading } from '@chakra-ui/react';
import { NextPage } from 'next';
import Router from 'next/router';
import React, { useCallback } from 'react';
import LoginForm from '~/components/LoginForm';
import { DEFAULT_ROUTE } from '~/routes';

const LoginPage: NextPage = () => {
  const handleLogin = useCallback(() => {
    Router.replace(DEFAULT_ROUTE);
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100vh">
      <Box as="section" shadow="sm" border={1} borderRadius={4} minWidth={320} p={4}>
        <Heading size="md" textAlign="center" mb={4}>
          플로렌스카드 어드민
        </Heading>
        <LoginForm onLogin={handleLogin} />
      </Box>
    </Box>
  );
};

export default LoginPage;
