import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { checkPassword, getUser } from '~/core/api/client';
import { ApiErrorCodes, queryApiErrorCode } from '~/core/api/errors';
import { useFirebase } from '~/core/firebase';

interface Props {
  onLogin?(): void;
}

function LoginForm({ onLogin }: Props) {
  const firebase = useFirebase();

  const [password, setPassword] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const handlePasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (firebase == null) {
        return;
      }

      setSubmitted(true);

      try {
        const validated = await checkPassword({ password }).toPromise();

        if (!validated) {
          setPasswordInvalid(true);
          return;
        }

        const provider = new firebase.auth.GoogleAuthProvider();
        const result = await firebase.auth().signInWithPopup(provider);

        if (result.user == null) {
          setSubmitted(false);
          return;
        }

        const { token } = await result.user.getIdTokenResult();
        await getUser({ token }).toPromise();

        onLogin?.();
      } catch (error) {
        let handled = false;

        switch (queryApiErrorCode(error)) {
          case ApiErrorCodes.인증실패:
          case ApiErrorCodes.접근권한_없음:
            handled = true;
            alert('허용된 사용자가 아닙니다.');
            break;
        }

        if (!handled) {
          alert(error?.message ?? '서버오류');
        }
      } finally {
        setSubmitted(false);
      }
    },
    [firebase, password, onLogin],
  );

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <FormControl isInvalid={passwordInvalid} mb={2}>
        <FormLabel htmlFor="password">비밀번호</FormLabel>
        <Input
          id="password"
          type="password"
          autoComplete="off"
          value={password}
          maxLength={50}
          placeholder="비밀번호 입력"
          isDisabled={submitted}
          onChange={handlePasswordChange}
        />
        <FormErrorMessage>비밀번호가 일치하지 않습니다.</FormErrorMessage>
      </FormControl>
      <Button colorScheme="purple" isFullWidth={true} type="submit" isLoading={submitted}>
        구글 로그인
      </Button>
    </Box>
  );
}

export default React.memo(LoginForm);
